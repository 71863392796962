<template>

    <div class="component-container">

        <h1>GUI documentation</h1>

        <div class="service">

            <h2>Retrieve literature associated to variants</h2>

            <div class="url">
                <b>URL:</b> {{ variomes_gui }}
            </div>

            <br/><h3>Parameters</h3>

            <table>
                <tr><th>Parameter name</th><th>Description</th><th>Example</th><th>Default value</th><th></th></tr>
                    <tr  v-for="(item, index) in gui.parameters" :class=isPair(index) :key="'GUI_'+index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.example }}<span v-if="item.details"><span class="details" v-for="detail in item.details" :key="detail"><br/>{{ detail }}</span></span></td>
                        <td>{{ item.default }}</td>
                        <td>{{ item.required }}</td>
                    </tr>
            </table>

            <br/><h3>Examples</h3>
            <ul>
                <li v-for="(item, index) in gui.examples" :key="'guiex_'+index"><a :href="getFullUrl('', item.url)" target="_blank">{{ getFullUrl('', item.text) }}</a></li>
            </ul>

        </div>

        <h1>API documentation</h1>

        <div class="service">

            <h2>Retrieve literature associated to a variant</h2>

            <div class="url">
                <b>URL:</b> {{ variomes_gui }}api/rankLit
            </div>

            <br/><h3>Parameters</h3>

            <table>
                 <tr><th>Parameter name</th><th>Description</th><th>Example</th><th>Default value</th><th></th></tr>
                    <tr  v-for="(item, index) in ranklit.parameters" :class=isPair(index) :key="'ranklit_'+index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.example }}<span v-if="item.details"><span class="details" v-for="detail in item.details" :key="detail"><br/>{{ detail }}</span></span></td>
                        <td>{{ item.default }}</td>
                        <td>{{ item.required }}</td>
                    </tr>
            </table>

            <br/><h3>Examples</h3>
            <ul>
                <li  v-for="(item, index) in ranklit.examples" :key="'litex_'+index"><a :href="getFullUrl('api/'+ranklit.url, item.url)" target="_blank">{{ getFullUrl('api/'+ranklit.url, item.text) }}</a></li>
            </ul>

        </div>

        <div class="service">

            <h2>Retrieve detailed information about a publication</h2>

            <div class="url">
                <b>URL:</b> {{ variomes_gui }}api/fetchDoc
            </div>

            <br/><h3>Parameters</h3>

            <table>
                 <tr><th>Parameter name</th><th>Description</th><th>Example</th><th>Default value</th><th></th></tr>
                    <tr  v-for="(item, index) in fetchlit.parameters" :class=isPair(index) :key="'fetch_'+index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.example }}<span v-if="item.details"><span class="details" v-for="detail in item.details" :key="detail"><br/>{{ detail }}</span></span></td>
                        <td>{{ item.default }}</td>
                        <td>{{ item.required }}</td>
                    </tr>
            </table>

            <br/><h3>Examples</h3>
            <ul>
                <li  v-for="(item, index) in fetchlit.examples" :key="'fetchex_'+index"><a :href="getFullUrl('api/'+fetchlit.url, item.url)" target="_blank">{{ getFullUrl('api/'+fetchlit.url, item.text) }}</a></li>
            </ul>


        </div>


        <div class="service">

            <h2>SIBiLS (Swiss Institute of Bioinformatics Literature Services)</h2>
            <p>Fetch APIs and search APIs of SIBiLS are described on this page:</p>
            <div class="url">
                <b>URL:</b> {{ sibils_url }}
            </div>

        </div>

         <div class="service">

            <h2>Retrieve synonyms for variant</h2>
            <p>The synvar service is described on this page:</p>
            <div class="url">
                <b>URL:</b> {{ synvar_url }}
            </div>

        </div>

    </div>

</template>

<script>
    import { mapState } from 'vuex'

    export default {
      name: 'API',
      data: function () {
        return {
            sibils_url: "https://sibils.org/api/",
            synvar_url: "https://synvar.sibils.org/resources/info.html",
            gui: {
                url: "rankLit",
                parameters: [
                {
                    name: "genvars",
                    description: "One or several genes and mutations",
                    example: "BRAF (V600E)",
                    default: "no default value",
                    required: "mandatory"
                },
                {
                    name: "disease",
                    description: "Any disease term",
                    example: "Uveal melanoma",
                    default: "no default value",
                    required: "optional"
                },
                {
                    name: "gender",
                    description: "The gender of the patient",
                    example: "male",
                    details: ["Possible values: male or female"],
                    default: "none",
                    required: "optional"
                },
                {
                    name: "age",
                    description: "The age of the patient (in year)",
                    example: "25",
                    default: "none",
                    required: "optional"
                },
                {
                    name: "minDate",
                    description: "Limit the search to publications published from the indicated year (included)",
                    example: "1980",
                    default: "2013",
                    required: "optional"
                },
                {
                    name: "maxDate",
                    description: "Limit the search to publications published until the indicated year (included)",
                    example: "2010",
                    default: "2023",
                    required: "optional"
                },
                {
                    name: "mustDisease",
                    description: "Indicate if the disease must be mentioned in the publication",
                    example: "false",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "mustGene",
                    description: "Indicate if the gene must be mentioned in the publication",
                    example: "true",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "mustVariant",
                    description: "Indicate if the variant must be mentioned in the publication",
                    example: "true",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "expandDisease",
                    description: "Indicate if synonyms of the disease must be used to expand the search",
                    example: "true",                    
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "expandGene",
                    description: "Indicate if synonyms of the gene must be used to expand the search",
                    example: "false",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "expandVariant",
                    description: "Indicate if synonyms of the variant must be used to expand the search",
                    example: "false",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "keywordsPositive",
                    description: "A list of terms that should be mentioned in the publication",
                    example: "surviv;treat",
                    details: ["A list of keywords separated by \";\""],
                    default: "",
                    required: "optional"
                },
                {
                    name: "keywordsNegative",
                    description: "A list of terms that should not be mentioned in the publication",
                    example: "sequencing;immuno;detect",
                    details: ["A list of keywords separated by \";\""],
                    default: "",
                    required: "optional"
                },
                {
                    name: "nb",
                    description: "Number of documents to retrieve",
                    example: "100",
                    default: "1000",
                    required: "optional"
                },
            ],
            examples: [
                {
                    url: "?genvars=P53%20(F270L)",
                    text: "?genvars=P53 (F270L)"
                },
                {
                    url: "?genvars=EGFR%20(L858R)&disease=melanoma&nb=10",
                    text: "?genvars=EGFR (L858R)&disease=melanoma&nb=10"
                },
                {
                    url: "?genvars=EGFR%20(L858R)&gender=male&age=14&minDate=1990",
                    text: "?genvars=EGFR (L858R)&gender=male&age=14&minDate=1990"
                },
            ]
            },
            ranklit: {
                url: "rankLit",
                parameters: [
                {
                    name: "genvars",
                    description: "One or several genes and mutations",
                    example: "BRAF (V600E)",
                    default: "no default value",
                    required: "mandatory"
                },
                {
                    name: "disease",
                    description: "Any disease term",
                    example: "Uveal melanoma",
                    default: "no default value",
                    required: "optional"
                },
                {
                    name: "gender",
                    description: "The gender of the patient",
                    example: "male",
                    details: ["Possible values: male or female"],
                    default: "none",
                    required: "optional"
                },
                {
                    name: "age",
                    description: "The age of the patient (in year)",
                    example: "25",
                    default: "none",
                    required: "optional"
                },
                {
                    name: "minDate",
                    description: "Limit the search to publications published from the indicated year (included)",
                    example: "1980",
                    default: "2013",
                    required: "optional"
                },
                {
                    name: "maxDate",
                    description: "Limit the search to publications published until the indicated year (included)",
                    example: "2010",
                    default: "2023",
                    required: "optional"
                },
                {
                    name: "collections",
                    description: "Collections to search in",
                    example: "medline,pmc,ct,supp",
                    details: ["medline: Medline", "pmc: PubmedCentral", "ct: ClinicalTrials.gov", "supp: Supplementary material"],
                    default: "medline",
                    required: "optional"
                },
                {
                    name: "mustDisease",
                    description: "Indicate if the disease must be mentioned in the publication",
                    example: "false",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "mustGene",
                    description: "Indicate if the gene must be mentioned in the publication",
                    example: "true",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "mustVariant",
                    description: "Indicate if the variant must be mentioned in the publication",
                    example: "true",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "expandDisease",
                    description: "Indicate if synonyms of the disease must be used to expand the search",
                    example: "true",                    
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "expandGene",
                    description: "Indicate if synonyms of the gene must be used to expand the search",
                    example: "false",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "expandVariant",
                    description: "Indicate if synonyms of the variant must be used to expand the search",
                    example: "false",
                    details: ["Possible values: true or false"],
                    default: "true",
                    required: "optional"
                },
                {
                    name: "keywordsPositive",
                    description: "A list of terms that should be mentioned in the publication",
                    example: "surviv;treat",
                    details: ["A list of keywords separated by \";\""],
                    default: "",
                    required: "optional"
                },
                {
                    name: "keywordsNegative",
                    description: "A list of terms that should not be mentioned in the publication",
                    example: "sequencing;immuno;detect",
                    details: ["A list of keywords separated by \";\""],
                    default: "",
                    required: "optional"
                },
                {
                    name: "nb",
                    description: "Number of documents to retrieve",
                    example: "100",
                    default: "1000",
                    required: "optional"
                },
            ],
            examples: [
                {
                    url: "?genvars=P53%20(F270L)",
                    text: "?genvars=P53 (F270L)"
                },
                {
                    url: "?genvars=EGFR%20(L858R)&disease=melanoma&nb=10",
                    text: "?genvars=EGFR (L858R)&disease=melanoma&nb=10"
                },
                {
                    url: "?genvars=EGFR%20(L858R)&gender=male&age=14&minDate=1990",
                    text: "?genvars=EGFR (L858R)&gender=male&age=14&minDate=1990"
                },
                {
                    url: "?genvars=EGFR%20(L858R)&disease=adenocarcinoma&collections=medline,pmc",
                    text: "?genvars=EGFR (L858R)&disease=adenocarcinoma&collections=medline,pmc"
                },
                {
                    url: "?genvars=BRCA1%20(C61G)%20OR%20NRAS%20(Q61R)&collection=pmc,ct&minDate=2010",
                    text: "?genvars=BRCA1 (C61G) OR NRAS (Q61R)&collection=pmc,ct&minDate=2010"
                },
                {
                    url: "?genvars=BRAF(V600E)%20AND%20BRAF(V600K)&gender=female&age=12&disease=melanoma&mustDisease=false",
                    text: "?genvars=BRAF(V600E AND BRAF(V600K)&gender=female&age=12&disease=melanoma&mustDisease=false"
                },
                {
                    url: "?genvars=BRAF(V600E)%20AND%20BRAF(V600K)&collection=supp",
                    text: "?genvars=BRAF(V600E) AND BRAF(V600K)&collection=supp"
                },
            ]
            },
            fetchlit: {
                url: "fetchDoc",
                parameters: [
                {
                    name: "ids",
                    description: "Any list of publications identifier (medline or pmc)",
                    example: "27145535;31647501",
                    default: "no default value",
                    required: "mandatory"
                },
                {
                    name: "genvars",
                    description: "One or several genes and mutations",
                    example: "BRAF (V600E)",
                    default: "no default value",
                    required: "mandatory"
                },
                {
                    name: "disease",
                    description: "Any disease term",
                    example: "Uveal melanoma",
                    default: "no default value",
                    required: "optional"
                },
                {
                    name: "gender",
                    description: "The gender of the patient",
                    example: "male",
                    details: ["Possible values: male or female"],
                    default: "none",
                    required: "optional"
                },
                {
                    name: "age",
                    description: "The age of the patient (in year)",
                    example: "25",
                    default: "none",
                    required: "optional"
                },
            ],
            examples: [
                {
                    url: "?ids=27145535;31671409",
                    text: "?ids=27145535;31671409"
                },
                {
                    url: "?ids=27145535;31671409&genvars=NRAS%20(Q61R)",
                    text: "?ids=27145535;31671409&genvars=NRAS (Q61R)"
                },
                {
                    url: "?ids=27145535;31671409;31647501&genvars=NRAS%20(Q61R)&disease=Juvenile%20Myelomonocytic%20Leukemia&age=2&gender=male",
                    text: "?ids=27145535;31671409;31647501&genvars=NRAS (Q61R)&disease=Juvenile%20Myelomonocytic%20Leukemia&age=2&gender=male"
                },
            ]
            }

        }
      },
      computed: {
        ...mapState(['urls']),
        variomes_gui (){
            var url = this.urls.variomes_gui
            if (process.env.NODE_ENV != 'production'){
                url = this.urls.variomes_gui_dev
            }
            return url
        }
      },
      methods: {
          isPair(val){
            if (val%2 == 0){
                return "pair"
            }
            else {
                return "impair"
            }
          },
          getFullUrl(service, item){
            var url = this.urls.variomes_gui
            if (process.env.NODE_ENV != 'production'){
                url = this.urls.variomes_gui_dev
            }
            return url+service+item
          },
      }
    }

</script>

<style scoped lang="scss">

    .service {
        border: 1px solid #eeeeee;
        padding: 10px;
        text-align: left;
        margin: 20px 0;
    }

    .url {
        background-color: #eeeeee;
        border: 1px solid #cccccc;
        padding: 10px;
    }

    table {
        width: 100%;
        text-align: left;
        font-size: 0.8em;
        border: 1px solid #D00000;
    }

    th {
     background-color: #D00000;
     color: white;
     padding: 5px 2px;
    }

    td {
     padding: 5px 5px;
     border: 1px solid #dddddd;
    }
    .pair {
     background-color: #eeeeee;
    }

    li {
        text-align: left;
        font-size: 0.8em;
    }
    a {
        font-weight: normal
    }

    .details{
        font-size: 0.8em;
        font-style: italic;

    }

</style>