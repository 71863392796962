<template>

    <div class="component-container">

        <h1>Tutorial</h1>

        <div class="section justified">
            <h2>How to search in Variomes?</h2>
            <p>
                First, you can select the type of query you would like to perform on Variomes.<br/>
                <img class="tuto-img-small" src="../assets/images/tutorial_query-type.png"/>
            </p>
            <p>
                <ul>
                    <li><span class="tuto-options">Search literature for one variant</span><br/>
                    You can search for a single variant or a combination of variants (e.g. multigenic disease). 
                    by directly entering a query containing one or more variants into the search bar. 
                    Use keywords "AND" and "OR" to mention several variants.
                    You can also enter a rs identifier and Variomes will suggest variants associated with your rs identifier. 
                    <br/><img class="tuto-img" src="../assets/images/tutorial_query-1.png"/>
                    </li>
                </ul>
                <ul>
                    <li><span class="tuto-options">Prioritize a set of variants</span><br/>
                    If you have a large number of variants (e.g. VCF files) and would like to find literature for all of them, 
                    you can upload a file with one variant per line, containing the gene name 
                    and the variant separated by a tab. 
                    Variants will be prioritized according to the amount of literature retrieved.
                    <br/><img class="tuto-img" src="../assets/images/tutorial_query-2.png"/>
                    </li>
                </ul>
                <ul>
                    <li><span class="tuto-options">Reload previous results</span><br/>
                    If you want to revisit a previous search or share your results with others,
                    you can enter the query identifier in the search bar. 
                    <br/><img class="tuto-img" src="../assets/images/tutorial_query-3.png"/>
                    </li>
                </ul>
            </p>

            <p>
                <span class="tuto-options">Patient information</span><br/>
                You can also add patient information, such as diagnosis, gender and age.
                <br/><img class="tuto-img-small" src="../assets/images/tutorial_query-patient.png"/>
            </p>
            <p>
                <span class="tuto-options">Advanced options</span><br/>
                You also have the possibility to adjust the advanced options. 
                <ul>
                    <li><b>Publication date</b> <br/>
                        By default, Variomes searches for literature in the last 10 years, 
                        but you can modify this date range.
                    </li>
                    <li><b>Number of documents to retrieve</b> <br/>
                        You can also modify the number of documents retrieved per collection. 
                        The fewer documents you request, the faster Variomes will perform.
                    </li>
                    <li><b>Information mandatory in the publication</b> <br/>
                        By default, documents retrieved by Variomes contain all the information mentionned in the query. 
                        ,but you can relax the constraints if one of the information is not mandatory. 
                    </li>
                    <li><b>Query expansion</b> <br/>
                        By default, Variomes expands gene, variant and disease names. 
                        , but you can decide not to expand one or several of these entity types.  
                    </li>
                </ul>
                <img class="tuto-img-small" src="../assets/images/tutorial_query-advanced.png"/>
            </p>

            <p>
                <span class="tuto-options">Conditions and search</span><br/>
                Before performing the search, you must agree to the conditions and press search.
                <br/><img class="tuto-img-small" src="../assets/images/tutorial_query-search.png"/>
            </p>

        </div>

        <div class="section justified">
            <h2>Query processing</h2>
            <p>
                Some queries can generate dozens of variant synonyms, resulting in a long processing time. 
                This is especially the case if you upload a large file of variants. 
                If you do no want to wait for the results, you can copy the link displayed in the yellow box and come back later.
                If your result do not show up after waiting a reasonable time, please <a target="_blank" href="mailto:emilie.pasche@hesge.ch">email us</a>. 
                <br/><img class="tuto-img" src="../assets/images/tutorial_processing.png"/>
            </p>
        </div>

        <div class="section justified">
            <h2>Results</h2>
            <p>
                <span class="tuto-options">Summary table</span><br/>
                If you queried with a file or a rs identifier, a summary table is displayed at the beginning of the results. 
                This summary provides publications counts for each collection, as well as a button to display the results for each variant. 
                <br/><img class="tuto-img" src="../assets/images/tutorial_results-summary.png"/>
            </p>
            <p>
                <span class="tuto-options">Collections</span><br/>
                Below, a panel with four tabs displays the documents per collection.
                For each collection, Variomes displays up to 10 publications per page with the ones deemed most relevant appearing first.
                <br/><img class="tuto-img" src="../assets/images/tutorial_results-collections.png"/>
            </p>
            <p>
                <span class="tuto-options">Sort and highlight</span><br/>
                On the left of the panel, you can switch the sorting to display most recent publication first. 
                You can also decide with entity types must be highlighted: gene, variant, disease, demographic information - if mentionned in your query.
                <br/><img class="tuto-img-mini" src="../assets/images/tutorial_results-sorthl.png"/>
            </p>
            <p>
                <span class="tuto-options">Filters</span><br/>
                On the left of the panel, you can also select filters to display only publications matching specific criteria (e.g. a journal, a language, a drug, etc.).
                If you select a filter and switch to another collection, you might have no result matching your filters. 
                <br/><img class="tuto-img-mini" src="../assets/images/tutorial_results-filters.png"/>
            </p>
            <p>
                <span class="tuto-options">Publication</span><br/>
                For each publication, bibliographic information will be displayed first (title, authors, publication date, journal, links to the abstract and/or full text).
                <br/>Then, markers will indicate which entity types of your query have been identified, displayed in green (present),
                orange (partially present) and red (absent).  
                This information is useful when you relax the constraint (e.g. allow the gene to be missing from the article, see query section).
                In the example below, the gene and the variant are present in the abstract. 
                <br/>Then, a set of passages containing the variant of your query will be displayed, along with the section from which each passage comes.
                By default, up to 3 passages are shown, but you can view more by clicking on the "Show more passages" button.
                <br/>In the top right corner, there is an icon that allows you to mark a publication as important.
                This feature enables you to export the publication along with other important publications you have marked.
                <br/>Also on the right, you can see the relevance score of your publication. 
                <br/>Finally, in the bottom right corner, you can click on the "+" icon to view additional
                 information about the publication (e.g. abstract, full-text article). 
                <br/><img class="tuto-img" src="../assets/images/tutorial_results-document.png"/>
            </p>
            <p>
                <span class="tuto-options">Reload your results</span><br/>
                On the bottom of the page, you can find the unique identifier of your query to reload or share your results.
                Please be aware that the publications that you have marked will not be saved. Do not forget to export them before leaving.
                <br/><img class="tuto-img" src="../assets/images/tutorial_results-reload.png"/>
            </p>
            <p>
                <span class="tuto-options">Export your results</span><br/>
                On the bottom of the page, you can find a summary table with the publication you selected. 
                You can export the list, in JSON format or CSV format. 
                Please be aware that the publications that you have marked will not be saved. Do not forget to export them before leaving.
                <br/><img class="tuto-img" src="../assets/images/tutorial_results-export.png"/>
            </p>
        </div>

        <div>

            <p>
               If you have any question, you can <a target="_blank" href="mailto:emilie.pasche@hesge.ch">contact us</a>.
            </p>


        </div>

    </div>

</template>

<script>

    export default {
      name: 'Tutor',
    }

</script>

<style scoped lang="scss">

    .section {
        padding: 0 0 40px 0;
    }

    .justified p {
        text-align: justify;
    }

    .centered p {
        text-align: center;
    }

    .publication {
        padding: 0 0 10px 0;
        text-align: justify
    }

    img {
        width: 100%
    }

    .tuto-img {
        width: 1000px;
        margin: 10px;
        border: 1px dotted #000
    }
    .tuto-img-small {
        width: 500px;
        margin: 10px;
        border: 1px dotted #000
    }

    .tuto-img-mini {
        width: 150px;
        margin: 10px;
        border: 1px dotted #000
    }

    .tuto-options {
        background-color: #e4aaaa;
        padding: 2px 10px;
        border-radius: 5px;
        font-weight: bold;
    }

    p {
        margin: 20px 20px 20px 20px;
    }

    h2 {
        text-align: left
    }


</style>