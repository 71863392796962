<template>

    <div class="component-container">

        <div class="section justified">
            <h1>About</h1>
            <p>Precision oncology relies on the use of treatments targeting specific genetic variants. However, identifying clinically actionable variants as well as relevant information likely to be used to treat a patient with a given cancer is a labor-intensive task, which includes searching the literature for a large set of variants. The lack of universally adopted standard nomenclature for variants requires the development of variant-specific literature search engines. We develop a system to perform triage of publications relevant to support an evidence-based decision. Together with providing a ranked list of articles for a given variant, the system is also able to prioritize variants, as found in a Variant Calling Format, assuming that the clinical actionability of a genetic variant is correlated with the volume of literature published about the variant. Our system searches within four pre-annotated document collections: MEDLINE abstracts, PubMed Central full-text articles, supplementary materials associated with publications, and ClinicalTrials.gov clinical trials. A variant synonym generator is used to increase the comprehensiveness of the set of retrieved documents. We then apply different strategies to rank the publications. </p>
        </div>

        <div class="section justified">
            <h1>Contact Us</h1>
            <p>Variomes is developed by the <a :href="url_sibtm" target="_blank">SIB Text Mining group</a> at the Swiss Institute of Bioinformatics and the <a :href="url_bitem" target="_blank">BiTeM group</a> at the University of Applied Sciences of Western Switzerland (HES-SO).</p>
            <p>For question, feedback or bug reporting, please contact <a :href="`mailto:${contact.mail}`">{{ contact.name }}</a>.</p>
        </div>

        <div class="section justified">
            <h1>Publications</h1>
            <div class="publication" v-for="(paper, index) in papers" :key="index">
                <span>[{{ index+1 }}] </span>
                <span>{{ paper.authors }}. </span>
                <span>{{ paper.title }}. </span>
                <span>{{ paper.journal }}.</span>
                <span>{{ paper.date }}</span>
                <span v-if="paper.page">:</span>
                <span>{{ paper.page }}. </span>
                <span>{{ paper.doi }}</span>
                <span v-if="paper.doi">. </span>
                <span><a :href="paper.link.url" target="_blank">{{ paper.link.name }}</a></span>
            </div>
        </div>
        <div class="section justified">
            <h1>Videos</h1>
            <div class="videos">
                <div class="video" v-for="(video, index) in videos" :key="index">
                    <h2>{{ video.title }}</h2>
                    <iframe width="560" height="315" :src="video.youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe><br/>
                    <a :href="video.link" target="_blank">Link to the source</a>
                </div>
            </div>
        </div>

        <div class="section justified">
            <h1>Acknowledgements</h1>
            <p>This Swiss Variant Interpretation Platform (<a :href="url_svip" target="_blank">SVIP</a>) project has been supported by the Swiss Personalized Health Network (SPHN) and the BioMedIT infrastructure. SVIP uses an integrated version of the Variomes services to support the curators of the clinical database. We would like to thank the SVIP project team members of the following groups: Clinical Bioinformatics Unit of NEXUS Personalized Health Technologies, SIB Clinical Bioinformatics and Swiss-Prot group, namely Daniel Stekhoven, Valérie Barbié, Anne Estreicher, Livia Famiglietti, Faisal Al Quaddoomi, David Meyer, Linda Grob, Franziska Singer and Nora Toussaint. The work presented in this report is built on top of <a :href="url_sibils" target="_blank">SIBiLS</a>, the SIB Literature Services, which is supported by the Elixir Data Platform. This work also benefited from discussions with Melissa Cline.</p>
        </div>

        <div class="section justified">
            <h1>More about Variomes</h1>
            <img src="../assets/images/dataflow_variomes.png">

        </div>

        <div class="section centered">
            <p>Last update: {{ update_date }}</p>
        </div>

    </div>

</template>

<script>

    export default {
      name: 'Home',
      data: function () {
        return {
            papers: [
                {
                    title: "Assessing the use of supplementary materials to improve genomic variant discovery",
                    authors: "Pasche E, Mottaz A, Gobeill J, Michel PA, Caucheteur D, Naderi N, Ruch P",
                    journal: "Database (Oxford)",
                    date: "2023 Mar 31",
                    issue: "2023",
                    page: "baad017",
                    doi: "doi: 10.1093/database/baad017",
                    link: {
                        url: "https://pubmed.ncbi.nlm.nih.gov/37002680/",
                        name: "PubMed"
                    }
                },
                {
                    title: "Variomes: a high recall search engine to support the curation of genomic variants",
                    authors: "Pasche E, Mottaz A, Caucheteur D, Gobeill J, Michel PA, Ruch P",
                    journal: "Bioinformatics",
                    date: "2022 Apr 28",
                    issue: "38(9)",
                    page: "2595–601",
                    doi: "doi: 10.1093/bioinformatics/btac146",
                    link: {
                        url: "https://pubmed.ncbi.nlm.nih.gov/35274687/",
                        name: "PubMed"
                    }
                },
                {
                    title: "Designing an Optimal Expansion Method to Improve the Recall of a Genomic Variant Curation-Support Service",
                    authors: "Mottaz A, Pasche E, Michel PA, Mottin L, Teodoro D, Ruch P",
                    journal: "Stud Health Technol Inform",
                    date: "2022 May 25",
                    issue: "294",
                    page: "839-843",
                    doi: "doi: 10.3233/SHTI220603",
                    link: {
                        url: "https://pubmed.ncbi.nlm.nih.gov/35612222/",
                        name: "PubMed"
                    }
                },
                {
                    title: "Text-mining Services of the Swiss Variant Interpretation Platform for Oncology",
                    authors: "Caucheteur D, Gobeill J, Mottaz A, Pasche E, Michel PA, Mottin L, Stekhoven DJ, Barbié V, Ruch P",
                    journal: "Stud Health Technol Inform",
                    date: "2020 Jun 16",
                    issue: "270",
                    page: "884-888",
                    doi: "doi: 10.3233/SHTI200288",
                    pmid: "32570509",
                    link: {
                        url: "https://pubmed.ncbi.nlm.nih.gov/32570509/",
                        name: "PubMed"
                    }
                }
            ],
            videos: [
                {
                    title: "SIB In Silico Talk",
                    date: "2023/09/01",
                    link: "https://www.sib.swiss/in-silico-talks/variomes-a-search-engine-to-support-the-curation-of-genetic-variants",
                    youtube: "https://www.youtube.com/embed/ovhu5U0EKHQ?si=YGXzAogaJw_VSrcy",
                },
                {
                    title: "Biocuration Conference 2023",
                    date: "2023/09/01",
                    link: "https://biocuration2023.github.io/abstracts",
                    youtube: "https://www.youtube.com/embed/x1jtumI7QRM?si=0n5qA-j9TEXJ6-0f",
                },

            ],
            update_date: "2023-09",
            url_bitem: "http://bitem.hesge.ch/",
            url_sibtm: "https://www.sib.swiss/patrick-ruch-group",
            url_sibils: "https://candy.hesge.ch/SIBiLS/",
            url_svip: "https://www.svip.ch",
            contact: {
                name: "Emilie Pasche",
                mail: "emilie.pasche@hesge.ch"
            }
           }
      }
    }

</script>

<style scoped lang="scss">

    .section {
        padding: 0 0 40px 0;
    }

    .justified p {
        text-align: justify;
    }

    .centered p {
        text-align: center;
    }

    .publication {
        padding: 0 0 10px 0;
        text-align: justify
    }

    .video {
        padding: 20px;
    }


    img {
        width: 100%
    }


</style>